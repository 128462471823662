import DISP_RESOURCE from './dispResource';

export const MODE = {
    SET_FLOOR: 'SET_FLOOR',
    SET_SPEAKERS: 'SET_SPEAKERS',
    SET_GROUPS: 'SET_GROUPS',
    SET_SCHEDULES: 'SET_SCHEDULES',
    SPEAKER_OPERATION: 'SPEAKER_OPERATION',
    USER_SURVEY: 'USER_SURVEY',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    LOADING: 'LOADING',
    VIEW_LOG: 'VIEW_LOG',
};

export const MODAL = {
    DISPLAY_MESSAGE: 'DISPLAY_MESSAGE',
    SAVE_FLOOR: 'SAVE_FLOOR',
    NEW_FLOOR: 'NEW_FLOOR',
    LOAD_FLOOR: 'LOAD_FLOOR',
    DELETE_FLOOR: 'DELETE_FLOOR',
    LOAD_DELETE_FLOOR_MSG: 'LOAD_DELETE_FLOOR_MSG',
    DETAIL_SPEAKER: 'DETAIL_SPEAKER',
    CHECK_GROUP: 'CHECK_GROUP',
    CONFIRM_DELETE_FLOOR: 'CONFIRM_DELETE_FLOOR',
    DELETE_SPEAKER: 'DELETE_SPEAKER',
    DELETE_GROUP: 'DELETE_GROUP',
    UNGROUP_SPEAKER: 'UNGROUP_SPEAKER',
    CONTRACT_LIMIT: 'CONTRACT_LIMIT',
    SAVE_FLOOR_MSG: 'SAVE_FLOOR_MSG',
    SAVE_FLOOR_CONFIRM: 'SAVE_FLOOR_CONFIRM',
    CHOICE_SAVE_FLOOR: 'CHOICE_SAVE_FLOOR',
    CHANGE_OPEARTION: 'CHANGE_OPEARTION',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    USER_CANNOT_PLAY: 'USER_CANNOT_PLAY',
    USER_FAILED_PLAY: 'USER_FAILED_PLAY',
    SELECT_STOP_TIME: 'SELECT_STOP_TIME',
    SELECT_SOUND_SOURCE: 'SELECT_SOUND_SOURCE',
    SURVEY_CONFIRM: 'SURVEY_CONFIRM',
    INVALID_LOG_PERIOD: 'INVALID_LOG_PERIOD',
    FAILED_GET_SOUND_SOURCE: 'FAILED_GET_SOUND_SOURCE',
    SYSTEM_ERROR: 'SYSTEM_ERROR',
};

export const GROUP_MODAL_TYPE = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const CHOICE_MODAL_OPT = {
    FIRST: '0',
    SECOND: '1',
    THIRD: '2',
    FOURTH: '3',
};

export const IMG_EXTN = [
    '.jpg',
    '.jpeg',
    '.jpe',
    '.png',
    '.JPG',
    '.JPEG',
    '.JPE',
    '.PNG',
];

export const REAL_WIDTH = {
    MIN: 30,
    MAX: 200,
    DEFAULT: 40,
};

export const ITEM_TYPES = {
    SIDEBAR_SPEAKER: 'SIDEBAR_SPEAKER',
    AREA_SPEAKER: 'AREA_SPEAKER',
};

export const SPEAKER_TYPE = {
    DEFAULT: 0,
    FAKEGREEN: 1,
    TABLE: 2,
    DESC: 3,
    PANEL: 4,
    SPOTLIGHT: 5,
};

/**
 * スピーカー状態
 */
export const SPEAKER_STATUS = {
    START: 'START',
    STOP: 'STOP',
};

export const SIDEBAR_SPEAKER_TYPE = [
    {
        name: DISP_RESOURCE.SPEAKER_DEFAULT,
        speakerTypeId: SPEAKER_TYPE.DEFAULT,
        url: require('../img/spDef.svg').default,
    },
    {
        name: DISP_RESOURCE.SPEAKER_FAKEGREEN,
        speakerTypeId: SPEAKER_TYPE.FAKEGREEN,
        url: require('../img/spGreen.svg').default,
    },
    {
        name: DISP_RESOURCE.SPEAKER_TABLE,
        speakerTypeId: SPEAKER_TYPE.TABLE,
        url: require('../img/spTable.svg').default,
    },
    {
        name: DISP_RESOURCE.SPEAKER_DESC,
        speakerTypeId: SPEAKER_TYPE.DESC,
        url: require('../img/spDesk.svg').default,
    },
    {
        name: DISP_RESOURCE.SPEAKER_PANEL,
        speakerTypeId: SPEAKER_TYPE.PANEL,
        url: require('../img/spPanel.svg').default,
    },
    {
        name: DISP_RESOURCE.SPEAKER_SPOTLIGHT,
        speakerTypeId: SPEAKER_TYPE.SPOTLIGHT,
        url: require('../img/spSpot.svg').default,
    },
];

// スピーカータイプ
export const IP_DEVICE_TYPE = [
    // スピーカーでない（出力なし）
    DISP_RESOURCE.NOT_SPEAKER,
    // 前方型スピーカー
    DISP_RESOURCE.DIRECTIONAL_SPEAKER,
    // 全方位型スピーカー
    DISP_RESOURCE.OMNIDIRECTIONAL_SPEAKER,
];

//**外部ファイル内容*/
export const EXTERNAL_FILE_CONTENTS = {
    DEFAULT_VOLUME: Number(process.env.REACT_APP_DEFAULT_VOLUME),
    SCHEDULER_DEFAULT_HEIGHT: Number(
        process.env.REACT_APP_SCHEDULE_DEFAULT_HEIGHT
    ),
    SCHEDULE_MAX_VOLUME: Number(process.env.REACT_APP_SCHEDULE_MAX_VOLUME),
    SCHEDULE_MIN_VOLUME: Number(process.env.REACT_APP_SCHEDULE_MIN_VOLUME),
    TEST_SOUND_SOURCE_ID: Number(process.env.REACT_APP_TEST_SOUND_SOURCE_ID),
};

/**
 * オフィス管理画面のモード
 */
export const OFFICE_CLIENT_MODE = {
    SETTING: '1',
    OPERATION: '2',
};

/**
 * スケジュール設定画面の更新パターン
 */
export const POLLING_PATTERN = process.env.REACT_APP_POLLING_CRON;

// セッションストレージ キー
export const SESSION_STORAGE_KEY = {
    USER_ACCESS_DATE: 'userAccessDate',
    USER_ID: 'userId',
    BASE_ID: 'baseId',
};

// 停止時間
export const STOP_TIME = {
    DEFAULT: 15,
    MIN: 15,
    MAX: 120,
    INTERVAL: 15,
};

// 音量スライダーの値
export const VOLUME_SLIDER = {
    MIN: -12,
    MAX: 12,
    STEP: 3,
};

/**
 * 拠点情報csvのヘッダー行
 */
export const BASE_SPEAKER = {
    BASE_SPEAKER_ID: 'baseSpeakerId',
    UNIT_NAME: 'unitName',
    UNIT_TYPE: 'unitTypeId',
    SPEAKER_TYPE: 'speakerTypeId',
    LOCATION: 'location',
    IP_ADDRESS: 'ipAddress',
    PORT: 'port',
    IS_USE: 'isUse',
};

//**スケジュール色（仮） */
export const SCHEDULE_COLORS = [
    { id: 0, hex: '#b51c1c' },
    { id: 1, hex: '#cd7f77' },
    { id: 2, hex: '#d25830' },
    { id: 3, hex: '#e5c545' },
    { id: 4, hex: '#6fb77c' },
    { id: 5, hex: '#478147' },
    { id: 6, hex: '#6094e0' },
    { id: 7, hex: '#4c45b1' },
    { id: 8, hex: '#8180c7' },
    { id: 9, hex: '#7d00a6' },
    { id: 10, hex: '#ff0080' },
    { id: 11, hex: '#b82362' },
];

/**
 * スケジュール日付表示タイプ
 */
export const SCHEDULE_VISIBLE_TYPE = {
    TWELVE_HOURS: '0',
    ONE_DAY: '1',
    SEVEN_DAYS: '2',
    ONE_WEEK: '3',
};

/**
 * 繰返し周期
 */
export const REPEAT_TYPE = {
    EVERY_DAY: '0',
    EVERY_WEEK: '1',
    PICK_DATE: '2',
    PICK_DAY: '3',
    EVERY_YEAR: '4',
    CUSTOM: '5',
};

/**
 * 繰返し（週月-曜日指定)の週項目
 */
export const WEEKS_COUNT = [
    DISP_RESOURCE.FIRST_WEEK,
    DISP_RESOURCE.SECOND_WEEK,
    DISP_RESOURCE.THIRD_WEEK,
    DISP_RESOURCE.FOURTH_WEEK,
    DISP_RESOURCE.FIFTH_WEEK,
];

/**
 * 音圧表示用設定
 */
export const SOUND_PRESSURE = [
    { volume: -12, distance: 1 },
    { volume: -9, distance: 1.41 },
    { volume: -6, distance: 1.99 },
    { volume: -3, distance: 2.82 },
    { volume: 0, distance: 3.98 },
    { volume: 3, distance: 5.63 },
    { volume: 6, distance: 7.94 },
    { volume: 9, distance: 11.22 },
    { volume: 12, distance: 15.84 },
];

/** スピーカーアイコンサイズ 音圧画像位置計算用の固定値 */
export const SPEAKER_ICON_SIZE = 56;

/**
 * ログ識別子
 */
export const CREATE_LOG_IDENTIFIER = {
    LOGIN: 'ログイン',
    WEB_PAGE_OPEN: 'Webページオープン',
    PLAY_SPEAKER: '音源START押下',
    STOP_SPEAKER: '音源STOP押下',
    TEST_PLAY_ON: 'テスト再生ON',
    TEST_PLAY_OFF: 'テスト再生OFF',
    MUTE_ON: 'ミュートON',
    MUTE_OFF: 'ミュートOFF',
    SOUND_SETTING: '音量操作',
    SOUND_SOURCE_SETTING: '音源操作',
    SURVEY_ACCESS: 'アンケートアクセス',
};

/**
 * ログ画面情報
 */
export const LOG = {
    PER_PAGE: 15,
    SORT_KEY: {
        TIME: 1,
        ACCOUNT: 2,
        SPK: 3,
        LOG: 4,
        RESULT: 5,
        IPADDRESS: 6,
    },
    SORT_TYPE: {
        ASC: 1,
        DESC: 2,
    },
};

/**
 * ページURL
 */
export const PAGE_URL = {
    URL: process.env.REACT_APP_PAGE_URL,
};

/**
 * ロケーション
 */
export const CALENDAR_LOCALE = {
    JP: 'ja-JA',
    EN: 'en-EN',
};

/**
 * オフィス利用者区分
 */
export const OFFICE_USER_TYPE = {
    ADMIN: '1',
    USER: '2',
};

/** スケジュールグループ表示接頭語
 */
export const SCHEDULE_GROUP_SPEAKER_ID_PREFIX = 'group_';

export const PERIOD_ERR_TYPE = {
    PERIOD_EMPTY: '0',
    AFTER_START: '1',
    NOT_ONE_DAY: '2',
};

// 繰返し最大年数
const REPEAT_LIMIT_YEAR = 1;

export const REPEAT_LIMIT = {
    // 繰返し（周年）専用
    YEAR: 30,
    MONTH: REPEAT_LIMIT_YEAR * 12,
    WEEK: Math.round((REPEAT_LIMIT_YEAR * 365) / 7),
};

/**
 * 入力フォームの入力文字上限
 */
export const FORM_MAX_LENGTH = {
    FLOOR_NAME: 20,
    GROUP_NAME: 20,
    SPEAKER_NAME: 20,
};

export const MAP_COORDINATE = {
    TOP: 164,
    LEFT: 515,
    MIN_TOP: -12,
    MIN_LEFT: -12,
    MAX_TOP: 674,
    MAX_LEFT: 1234,
};

export const SPEAKER_LIST_STATUS = {
    PLAY: 'Play / Normal',
    STOP: 'Stop / Normal',
    FAIL: '-- / Failure',
    MUTE: 'Mute / Normal',
};

/**
 * QRコードのダウンロードサイズ
 */
export const QRCODE_DOWNLOAD_SETTING = {
    URL: PAGE_URL.URL + 'user',
    QUERY: '?speakerId={0}&baseId={1}',
    SIZE: 600,
};

/**
 * 拠点情報CSV反映時の更新種類
 */
export const BASE_SPEAKER_CHANGE_TYPE = [
    { key: 1, type: DISP_RESOURCE.ADD },
    { key: 2, type: DISP_RESOURCE.UPDATE },
    { key: 3, type: DISP_RESOURCE.DELETE },
];

/**
 * 通常のフロアマップの画像サイズ
 */
export const FLOOR_MAP_SIZE_BASIS = {
    WIDTH: 1280,
    HEIGHT: 720,
    PIN_SIZE: 36,
};

/**
 * Cookieに登録する情報
 */
export const COOKIE_KEY = {
    SCHEDULE_VISIBLE_TYPE: 'scheduleVisibleType',
    SCHEDULE_START_TIME: 'scheduleStartTime',
    SOUND_PRESSURE: 'soundPressure',
    LOAD_FLOOR_ID: 'loadFloorId',
    FLOOR_JOINED_BASE_ID: 'floorJoinedBaseId',
}