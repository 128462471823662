import React, { createRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Button, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ReactPaginate from 'react-paginate';

import DISP_RESOURCE from '../../common/dispResource';
import ModalMessage from './modal/ModalMessage';

import 'react-datepicker/dist/react-datepicker.css';
import { LOG, MODAL } from '../../common/constants';

import * as LogApi from '../../api/logApi';

/**
 * ログ確認画面の作成
 */
class Log extends React.Component {
    /**
     * @param {*} props.baseId 拠点ID
     */
    constructor(props) {
        super(props);

        this.state = {
            // ログリスト
            logList: [],
            // 現在ページ
            currentPage: 0,
            // 表示開始日
            startDate: new Date(),
            // 表示終了日
            endDate: new Date(),
            // 表示開始日（設定済み）
            filterStartDate: null,
            // 表示終了日（設定済み）
            filterEndDate: null,
            // ソートキー
            sortKey: null,
            // ソートタイプ
            sortType: null,
        };

        this[MODAL.INVALID_LOG_PERIOD] = createRef();
    }

    setLogList = (list) => {
        this.setState({ logList: list });
    };

    /**
     * 画面レンダリング、ログリスト初期化（最上段の拠点自動選択）
     */
    componentDidMount = () => {
        this.init(this.props.baseId);
    };

    /**
     * ログリスト初期化
     */
    init = async (baseId) => {
        const startDate = new Date();
        const endDate = new Date();

        const start =
            startDate !== null
                ? moment(this.state.startDat).format('YYYY/MM/DD')
                : null;
        const end =
            endDate !== null
                ? moment(this.state.endDate).format('YYYY/MM/DD')
                : null;

        // ログ表示情報初期化
        this.setState({
            startDate: startDate,
            endDate: endDate,
            filterStartDate: start,
            filterEndDate: end,
            sortKey: null,
            sortType: null,
        });

        // 初期状態でのログリスト取得条件params
        const filter = {
            baseId,
            startDate: start,
            endDate: end,
        };

        // ログ取得API
        const logData = await LogApi.getUserLogs(filter);

        // ログリスト取得
        this.setLogList(logData.logInfo);

        // ページ初期化
        this.changePage({ selected: 0 });
    };

    /**
     * ログリスト初期化（条件付き）
     * @param {*} filter 条件パラメータ
     */
    initWithFilter = async (filter) => {
        // ログ取得API
        const logData = await LogApi.getUserLogs(filter);

        // ログリスト取得
        this.setLogList(logData.logInfo);

        // ページ初期化
        this.changePage({ selected: 0 });
    };

    /**
     * 現在ページの表示リスト取得
     */
    getDisplayList = () => {
        const { currentPage, logList } = this.state;
        // 取得開始件数
        const from = (currentPage + 1) * LOG.PER_PAGE - LOG.PER_PAGE;
        // 取得終了件数
        const to = from + LOG.PER_PAGE;
        // 押下時に表示する15件のログリストをstateにセット
        return logList.slice(from, to);
    };

    /**
     * ページNo.変更
     * @param {number} {selected} 選択ページNo.
     */
    changePage = ({ selected }) => {
        let pageNumber = selected;
        this.setState({ currentPage: pageNumber });
    };

    /**
     * [表示更新]ボタン押下時 指定期間のログを表示
     */
    handleUpdate = () => {
        const { startDate, endDate, sortKey, sortType } = this.state;
        const { baseId } = this.props;

        const start =
            startDate !== null ? moment(startDate).format('YYYY/MM/DD') : null;
        const end =
            endDate !== null ? moment(endDate).format('YYYY/MM/DD') : null;

        if (start > end) {
            this[MODAL.INVALID_LOG_PERIOD].current.init();
            return;
        }

        // ログリスト取得条件params
        const filter = {
            baseId: baseId,
            startDate: start,
            endDate: end,
            sortKey,
            sortType,
        };

        // 設定済み日時の更新
        this.setState({ filterStartDate: start, filterEndDate: end });

        this.initWithFilter(filter);
    };

    /**
     * リスト項目の降順、昇順（▼▲）ボタン押下時 指定条件のログを表示
     * @param {number} key   ソートキー
     * @param {number} type  ソートタイプ
     */
    handleSort = (key, type) => {
        const { filterStartDate, filterEndDate, sortKey, sortType } =
            this.state;
        const { baseId } = this.props;

        let filter = {};

        if (sortKey === key && sortType === type) {
            // 同一ボタン押下時、ソート条件を解除
            this.setState({ sortKey: null, sortType: null });
            filter = {
                baseId: baseId,
                startDate: filterStartDate,
                endDate: filterEndDate,
                sortKey: null,
                sortType: null,
            };
        } else {
            // ログリスト取得のソート条件追加
            this.setState({ sortKey: key, sortType: type });
            filter = {
                baseId: baseId,
                startDate: filterStartDate,
                endDate: filterEndDate,
                sortKey: key,
                sortType: type,
            };
        }

        this.initWithFilter(filter);
    };

    /**
     * 空欄のログの欄を作成
     */
    emptyList = () => {
        const length = LOG.PER_PAGE - this.getDisplayList().length;
        let emptyList = [];

        for (let i = 0; i < length; i++) {
            emptyList.push(
                <tr id={`${i}-logEmpty`} key={`${i}-logEmpty`}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            );
        }
        return emptyList;
    };

    render() {
        const { logList, startDate, endDate, sortKey, sortType, currentPage } =
            this.state;
        return (
            <div className="base_log">
                <div className="log_header">
                    <div className="log_search_date">
                        <Label
                            for="log_period_start"
                            className="label_log_period_start"
                        >
                            {DISP_RESOURCE.LOG_PERIOD}
                        </Label>
                        <DatePicker
                            className="form-control form-calendar"
                            selected={startDate}
                            onChange={(date) =>
                                this.setState({ startDate: date })
                            }
                            showIcon
                            dateFormat="yyyy/MM/dd"
                        />
                        <Label
                            for="log_period_end"
                            className="label_log_period_end"
                        >
                            ～
                        </Label>
                        <DatePicker
                            className="form-control form-calendar"
                            selected={endDate}
                            onChange={(date) =>
                                this.setState({ endDate: date })
                            }
                            minDate={startDate}
                            showIcon
                            dateFormat="yyyy/MM/dd"
                        />
                    </div>
                    <div className="log_update">
                        <Button
                            color="success"
                            onClick={this.handleUpdate}
                            className="log_update_button"
                        >
                            {DISP_RESOURCE.LOG_UPDATE}
                        </Button>
                    </div>
                </div>

                <table className="table log_table">
                    <thead className="table_success">
                        <tr>
                            {/* Date */}
                            <th>
                                <div className="log_table_header log_date">
                                    {DISP_RESOURCE.LOG_DATE}
                                </div>
                            </th>
                            {/* Time */}
                            <th>
                                <div className="log_table_header log_time">
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.TIME === sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.TIME,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    {DISP_RESOURCE.LOG_TIME}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.TIME === sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.TIME,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                            {/* Account */}
                            <th>
                                <div className="log_table_header log_account">
                                    {DISP_RESOURCE.LOG_ACCOUNT}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.ACCOUNT ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.ACCOUNT,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.ACCOUNT ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.ACCOUNT,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                            {/* SPK ID */}
                            <th>
                                <div className="log_table_header log_speaker">
                                    {DISP_RESOURCE.LOG_SPEAKER_NAME}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.SPK === sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.SPK,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.SPK === sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.SPK,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                            {/* Log */}
                            <th>
                                <div className="log_table_header log_identifier">
                                    {DISP_RESOURCE.LOG_LOG}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.LOG === sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.LOG,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.LOG === sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.LOG,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                            {/* Result */}
                            <th>
                                <div className="log_table_header log_result">
                                    {DISP_RESOURCE.LOG_RESULT}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.RESULT ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.RESULT,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.RESULT ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.RESULT,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                            {/* Data */}
                            <th>
                                <div className="log_table_header log_data">
                                    {DISP_RESOURCE.LOG_IP_ADDRESS}
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.IPADDRESS ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.ASC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.IPADDRESS,
                                                LOG.SORT_TYPE.ASC
                                            )
                                        }
                                    >
                                        ▲
                                    </span>
                                    <span
                                        className={classNames({
                                            active:
                                                LOG.SORT_KEY.IPADDRESS ===
                                                    sortKey &&
                                                LOG.SORT_TYPE.DESC === sortType,
                                        })}
                                        onClick={() =>
                                            this.handleSort(
                                                LOG.SORT_KEY.IPADDRESS,
                                                LOG.SORT_TYPE.DESC
                                            )
                                        }
                                    >
                                        ▼
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getDisplayList().map((item, index) => {
                            const {
                                logDate,
                                logTime,
                                account,
                                speakerName,
                                logIdentifier,
                                result,
                                ipAddress,
                            } = item;
                            return (
                                <tr key={`${index}-log`}>
                                    <td>
                                        {moment(logDate).format('YYYY/MM/DD')}
                                    </td>
                                    <td>{logTime ? logTime : '-'}</td>
                                    <td>{account ? account : '-'}</td>
                                    <td>{speakerName ? speakerName : '-'}</td>
                                    <td>
                                        {logIdentifier ? logIdentifier : '-'}
                                    </td>
                                    <td>
                                        {result !== null
                                            ? result
                                                ? DISP_RESOURCE.LOG_RESULT_SUCCESS
                                                : DISP_RESOURCE.LOG_RESULT_FAILED
                                            : DISP_RESOURCE.LOG_RESULT_NOTHING}
                                    </td>
                                    <td>{ipAddress}</td>
                                </tr>
                            );
                        })}
                        {this.emptyList()}
                    </tbody>
                </table>
                <div className="log_pagination">
                    <ReactPaginate
                        forcePage={currentPage}
                        pageCount={Math.ceil(logList.length / LOG.PER_PAGE)} // 総ページ数。一覧表示したいデータ数 / 1ページあたりの表示数
                        marginPagesDisplayed={1} // 先頭と末尾に表示するページの数
                        pageRangeDisplayed={4} // 今いるページの前後の番号の表示数
                        onPageChange={this.changePage} // ページネーションのリンクをクリックしたときのイベント
                        containerClassName="pagination" // ページネーションリンクの親要素のクラス名
                        pageClassName="page-item" // 各子要素(li要素)のクラス名
                        pageLinkClassName="page-link" // ページネーションのリンクのクラス名
                        activeClassName="active" // 今いるページ番号のクラス名
                        previousLabel={
                            <img
                                alt="left_arrow"
                                src={
                                    require('../../img/left_arrow_d.svg')
                                        .default
                                }
                            />
                        } // 前のページ番号に戻すリンクのテキスト
                        nextLabel={
                            <img
                                alt="right_arrow"
                                src={
                                    require('../../img/right_arrow_d.svg')
                                        .default
                                }
                            />
                        } // 次のページに進むボタンのテキスト
                        previousClassName="page-left" // '<'の親要素(li)のクラス名
                        nextClassName="page-right" // '>'の親要素(li)のクラス名
                        previousLinkClassName="page-link-left" // '<'のリンクのクラス名
                        nextLinkClassName="page-link-right" // '>'のリンクのクラス名
                        disabledClassName="disabled" // 先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくするためのクラス
                        breakLabel="..." // ページがたくさんあるときに表示しない番号に当たる部分のテキスト
                        breakClassName="page-item" // 上記の「…」のクラス名
                        breakLinkClassName="page-link" // 「…」の中のリンクにつけるクラス
                    />
                </div>
                {/* FROM > TO のエラーメッセージ */}
                <ModalMessage
                    ref={this[MODAL.INVALID_LOG_PERIOD]}
                    title={''}
                    content={DISP_RESOURCE.PERIOD_ERROR_START_LATE_THEN_END}
                    okString={DISP_RESOURCE.CLOSE}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
            </div>
        );
    }
}
export default Log;
